
import { defineComponent } from 'vue';
import PageHeader from '@/components/PageHeader.vue';
import BackBtn from '@/components/BackBtn.vue';

export default defineComponent({
  name: 'NotFound',
  components: {
    PageHeader, BackBtn
  }
});
